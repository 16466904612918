<template>
  <div class="about">
    <h1>联系我们</h1>

    <el-row :gutter="20">
      <el-col :span="12">
        <div class="tbox">
          <h2>您有任何建议或反馈，请随时与我们联系！</h2>
          公司：瑞小安科技（深圳）有限公司<br />
          电话：0755-8616 5800 <br />
          邮件：marketing@ryan-doctor.com<br />
          地址：深圳市南山区西丽街道丽城社区西丽北路2-2号沙河西里329<br />
          邮编：518000
        </div>
      </el-col>
      <el-col :span="12" style="margin-top: 30px">
        <el-form ref="form" :model="form" label-width="80px">
          <el-form-item label="姓名">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="联系方式">
            <el-input v-model="form.phone"></el-input>
          </el-form-item>
          <el-form-item label="留言">
            <el-input type="textarea" v-model="form.desc"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="success" @click="onSubmit">提交</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>
 <style>
.about {
  margin: 120px auto;
  padding: 0 20%;
  text-align: left;
  line-height: 40px;
}
</style>
<script>
import { ElMessage } from "element-plus";
export default {
  data() {
    return {
      form: {
        name: "",
        phone: "",
        desc: "",
      },
    };
  },
  methods: {
    onSubmit() {
      if (this.$data.form.name == "") {
        ElMessage.warning({
          message: "请填写姓名",
          type: "warning",
        });
        return;
      }
      if (this.$data.form.phone == "") {
        ElMessage.warning({
          message: "请填写联系方式",
          type: "warning",
        });
        return;
      }
      if (this.$data.form.desc == "") {
        ElMessage.warning({
          message: "请填写留言",
          type: "warning",
        });
        return;
      }
      setTimeout(() => {
        ElMessage.success({
          message: "提交成功",
          type: "success",
        });
      }, 600);
    },
  },
};
</script>